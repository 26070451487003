import React from "react";
import Logo from "../../blocks/logo/Logo";
import LogoImage from "../../assets/img/logo/logo_kb_lightblue_dot.svg";
import MenuModal from "../../components/modal/MenuModal";

const Header = () => {
  return (
    <header id="header" className="site-header">
      <div className="wrapper d-flex justify-content-between">
        <div className="align-self-center">
          <Logo image={LogoImage} />
        </div>

        <MenuModal />
      </div>
    </header>
  );
};

export default Header;
